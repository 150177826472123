:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

:export{
  mainFont:"Rubik";
  bodyFonts:"Rubik",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
  monospaced:"Fira Mono"
}

:export{
  sizeNormal:.875rem
}

:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

.f-col{
  padding:.5rem
}

::v-deep .btn-next li,::v-deep .btn-prev li,::v-deep .el-pager li{
  background-color:#fff !important;
  box-shadow:0 6px 10px 0 rgba(22,34,57,.05);
  width:61px;
  height:32px
}

@media screen and (max-width: 600px){
  ::v-deep .btn-next li,::v-deep .btn-prev li,::v-deep .el-pager li{
    width:auto
  }
}

@media(min-width: 550px)and (max-width: 769px){
  ::v-deep .btn-next li,::v-deep .btn-prev li,::v-deep .el-pager li{
    width:51px
  }
}

::v-deep .btn-next li:not(.disabled),::v-deep .btn-prev li:not(.disabled),::v-deep .el-pager li:not(.disabled){
  color:#9aa3b4 !important;
  font-size:15px;
  font-weight:400
}

::v-deep .btn-next li.active,::v-deep .btn-prev li.active,::v-deep .el-pager li.active{
  color:#162239 !important;
  border-bottom:1px solid #5921a0;
  border-radius:4px
}

::v-deep .el-input__inner{
  border:none;
  box-shadow:0 6px 10px 0 rgba(22,34,57,.05);
  height:32px;
  color:#9aa3b4;
  font-size:12px !important
}

::v-deep .el-pagination__rightwrapper{
  margin-top:1rem;
  display:flex;
  width:100%;
  justify-content:space-between
}

@media (min-width: 426px){
  ::v-deep .el-pagination__rightwrapper{
    margin-top:0px;
    justify-content:flex-end
  }
}

::v-deep .el-pagination__rightwrapper .btn-prev, ::v-deep .el-pagination__rightwrapper .btn-next{
  height:2rem;
  width:3.5rem;
  align-self:flex-start;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow:0 6px 10px 0 rgba(22, 34, 57, 0.05);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width: 426px){
  ::v-deep .el-pagination__rightwrapper .el-pagination__sizes{
    order:-9999
  }
}

::v-deep .el-pagination__rightwrapper .el-pagination__sizes .el-select .el-input{
  width:100%
}