
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.el-container {
    ::v-deep {
        //.el-table__header-wrapper {
        //    .el-table__header {
        //        min-width: 992px;
        //    }
        //}
        //
        .el-table__body-wrapper {
            color: #162239;
            //overflow: auto;
            //
            //.el-table__body {
            //    min-width: 992px;
            //}
        }
        .cell {
            word-break: break-word;
        }

        .el-table thead {
            color: white;
        }

        .el-table td {
            height: 60px;
        }
        
        .el-table th {
            font-weight: 300;
            background-color: #9AA3B4;
            border: #9AA3B4 solid 1px;
            padding: 0;
            height: 60px;
            color: white;
            width: 100%;
        }

        .el-table__append-wrapper {
            min-width: 992px;
        }

        .options {
            .cell {
                @apply flex w-full justify-around;
            }
        }
    }
}

::v-deep {
    .el-checkbox {
        &__input.is-focus {
            .el-checkbox__inner {
                border-color: #DCDFE6;
            }
        }

        &__input.is-checked {
            .el-checkbox__inner {
                background: #FFFFFF;
                border-color: #DCDFE6;

                &:after {
                    transform: rotate(0deg) scaleY(1);
                    left: 5px;
                    top: 5px
                }
            }

            +.el-checkbox__label {
                color: inherit;
            }
        }

        &__input.is-indeterminate {
            .el-checkbox__inner {
                background: #FFFFFF;
                border-color: #DCDFE6;

                &:hover, &:active, &:focus {
                    border-color: #DCDFE6;
                }

                &:after {
                    transform: rotate(0deg) scaleY(1);
                    left: 5px;
                    top: 5px;
                }

                &:before {
                    content: none;
                }
            }

            +.el-checkbox__label {
                color: inherit;
            }
        }

        &__inner {
            border-radius: 0;
            width: 20px;
            height: 20px;
            transition: none;

            &:hover, &:active, &:focus {
                border-color: #DCDFE6;
            }

            &:after {
                width: 8px;
                height: 8px;
                border: none;
                background: rgba(22, 34, 57, 1);
                transition: none;
            }
        }
    }
}
