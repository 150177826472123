
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .btn-next,
    .btn-prev,
    .el-pager {
        li {
            background-color: white!important;
            box-shadow: 0 6px 10px 0 rgba(22, 34, 57, 0.05);
            width: 61px;
            height: 32px;
            @media screen and (max-width: 600px){
                width: auto;
            }

            @media (min-width: 550px) and (max-width: 769px) {
                width: 51px;
            }
    
            &:not(.disabled){
                color: #9AA3B4!important;
                font-size: 15px;
                font-weight: 400;
            }
    
            &.active {
                color: #162239!important;
                border-bottom: 1px solid #5921A0;
                border-radius: 4px;
            }
        }
    }
    
    
    
    .el-input__inner {
        border: none;
        box-shadow: 0 6px 10px 0 rgba(22, 34, 57, 0.05);
        height: 32px;
        color: #9AA3B4;
        font-size: 12px!important;
    }

    .el-pagination__rightwrapper {
        @apply flex justify-between w-full mt-4 tablet:mt-0 tablet:justify-end;

        .btn-prev, .btn-next {
            @apply bg-white shadow-md w-14 h-8 self-start;
        }

        /*.btn-prev {*/
        /*    @apply order-first tablet:order-none;*/
        /*}*/

        .el-pagination__sizes {
            @apply tablet:order-first;

            .el-select {

                .el-input {
                    @apply w-full;
                }
            }
        }
    }
    
    /*@media screen and (max-width: 991px){*/
    /*    .el-pagination__rightwrapper {*/
    /*        margin-top: 8px;*/
    /*    }*/
    /*}*/
}


